<template>
  <v-tabs
    id="replay_tabs"
    class="pt-0 pt-sm-3"
    hide-slider
    center-active
    centered
    show-arrows
  >

    <!--  탭 버튼  -->
    <v-tab
      class="c-tt-none black--text"
      :to="{ query: {} }"
      :disabled="!gc_pageLoaded"
      exact
    >
      All
    </v-tab>

    <template v-for="(item, i) in list">
      <v-tab
        class="c-tt-none black--text"
        :key="`tab-item-${i}`"
        :to="{ query: { category: item.id } }"
        :disabled="!gc_pageLoaded"
        exact
      >
        {{ item[`name${gc_lang_prefix}`] }}
      </v-tab>
    </template>

  </v-tabs>
</template>

<script>
export default {
  name: "Tabs",

  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="sass" scoped>
</style>