<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    width="480"
    :persistent="requesting"
    scrollable
  >
    <v-card tile>

      <v-card-title class="headline cyan white--text">
        Question
      </v-card-title>

      <!--  질문 입력  -->
      <v-card-text class="pt-5 pb-0 c-alert-text">
        <v-form
          ref="form"
          v-model="valid"
          :disabled="requesting"
        >
          <v-text-field
            :label="gc_langText.replay.text.title[gc_lang]"
            :value="item[`name${gc_lang_prefix}`]"
            outlined
            readonly
            dense
          />
          <v-text-field
            :label="gc_langText.replay.text.presenter[gc_lang]"
            :value="item[`speaker${gc_lang_prefix}`]"
            outlined
            readonly
            dense
          />
          <v-textarea
            v-model="content"
            :label="gc_langText.replay.text.content[gc_lang]"
            :rules="[gc_rules.required]"
            outlined
            dense
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-spacer/>
        <!--  버튼: 닫기  -->
        <v-btn
          @click="show = false"
          :disabled="requesting"
          plain
          tile
        >
          {{ gc_langText.common.alert.closeText2[gc_lang] }}
        </v-btn>

        <!--  버튼: 전송  -->
        <v-btn
          @click="submit"
          color="cyan"
          elevation="0"
          :loading="requesting"
          dark
          tile
        >
          {{ gc_langText.replay.btn.submit[gc_lang] }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "QuestionDialog",

  props: {
    // 부모로부터 팝업 토글 여부를 받는다 (부모 속성이므로 직접 컨트롤 불가)
    _show: {
      type: Boolean,
      default: false
    },

    // 참고할 컨텐츠 정보 객체
    item: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    // 팝업 토글 여부
    show: false,

    // 질문 등록 요청 중
    requesting: false,

    // 질문 내용
    content: '',

    // 유효성 검사
    valid: true
  }),

  watch: {
    // 부모로부터 전달 받은 속성 변경 값 자체 속성 값과 링크
    _show(val) {
      this.show = val
    },

    // 자체 속성값 "닫기" 로 변경시 부모 메서드 호출
    show(val) {
      !val && this.$emit('questionClose')
      !val && this.$refs.form.reset()
    }
  },

  methods: {
    // 질문 전송
    submit() {
      this.$refs.form.validate()

      if (!this.valid) { return }

      this.api_submitVodQuestion()
    }
  }
}
</script>

<style scoped>

</style>