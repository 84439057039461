<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    fullscreen
    scrollable
    dark
  >
    <v-card
      id="pdf-viewer"
      class="px-16 py-4 mt-16"
      @contextmenu.prevent=""
      tile
    >

      <!--  버튼: 그룹  -->
      <v-card-actions>
        <!--  축소  -->
        <v-btn
          @click="scale.insertScale -= 25"
          :disabled="scale.insertScale <= -75"
          :loading="scale.loading"
          text
        >
          <v-icon>mdi-minus-box-outline</v-icon>
        </v-btn>

        <!--  원본  -->
        <v-btn
          @click="scale.insertScale = 0"
          :disabled="scale.insertScale === 0"
          :loading="scale.loading"
          text
        >
          <v-icon>mdi-text-box-outline</v-icon>
        </v-btn>

        <!--  확대  -->
        <v-btn
          @click="scale.insertScale += 25"
          :disabled="scale.insertScale >= 100"
          :loading="scale.loading"
          text
        >
          <v-icon>mdi-plus-box-outline</v-icon>
        </v-btn>

        <v-spacer/>

        <!--  닫기  -->
        <v-btn
          @click="show = false"
          text
        >
          <span class="mr-2">Close</span>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>

      <!--  컨텐츠 박스  -->
      <v-card-text class="pa-0">
        <v-row
          class="flex-column-reverse flex-sm-row ma-0 mx-md-auto"
          :style="scaleStyle"
        >
          <!--  PDF 뷰어  -->
          <v-col
            v-show="loaded"
            class="pa-0"
            cols="12"
          >
            <div
              v-for="i in pages"
              :key="`pdf-${i}`"
              class="my-3 c-pdf-wrap"
            >
              <pdf
                :src="src"
                :page="i"
              />

              <span
                class="c-page"
              >{{ i }} / {{ pages }}</span>
            </div>
          </v-col>

          <!--  로더 UI  -->
          <v-col
            v-if="!loaded"
            class="px-0 d-flex justify-center"
            cols="12"
            md="10"
            offset-md="1"
          >
            <v-progress-circular
              :size="64"
              :width="8"
              class="my-16"
              color="cyan"
              indeterminate
            />
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ReplayDialogPdf",

  props: {
    // 부모로부터 팝업 토글 여부를 받는다 (부모 속성이므로 직접 컨트롤 불가)
    _show: {
      type: Boolean,
      default: false
    },

    // 보여줄 컨텐츠 정보 객체
    _src: {
      type: String,
      default: ''
    },
  },

  data: () => ({
    // 팝업 토글 여부
    show: false,

    // PDF 로드 완료 여부
    loaded: false,

    // PDF src
    src: null,

    // PDF 페이지
    pages: null,

    // 뷰어 스케일
    scale: {
      defaultMaxWidth: 1280,
      defaultScale: 100,
      insertScale: 0,
      loading: false
    }
  }),

  computed: {
    scaleStyle() {
      const def = 'margin-left: auto !important; margin-right: auto !important;'
      const width = this.scale.defaultScale + this.scale.insertScale
      const maxWidth = this.scale.defaultMaxWidth * width / 100

      return `${def} width: ${width}%; max-width: ${maxWidth}px`
    }
  },

  watch: {
    _show(val) {
      this.show = val
    },

    // 자체 속성값 "닫기" 로 변경시 부모 메서드 호출
    show(val) {
      // 팝업이 열릴 경우
      if (val) {
        const it = this
        this.src = this.PDF.createLoadingTask(this._src)
        this.src.promise.then( pdf => {
          it.loaded = true
          it.pages = pdf.numPages
        } )
      }

      // 팝업을 닫을 경우
      else if (!val) {
        this.loaded = false
        this.errored = false
        this.$emit('closePdf')
      }
    },

    // 스케일 변경시마다 켄버스를 다시 그리는데 너무 빠르게 하면 켄버스 이미지 깨지는 이슈 있음
    // 따라서 딜레이 줌. 좋은 해결책은 아니지만 라이브러리를 수정할수 없어서 임시방편으로 해놓음
    'scale.insertScale'() {
      const it = this

      this.scale.loading = true
      setTimeout(() => it.scale.loading = false, 500)
    }
  }
}
</script>

<style lang="sass" scoped>
#pdf-viewer
  overflow: hidden
  max-height: 100vh
  user-select: none
  .c-pdf-wrap
    position: relative
    .c-page
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)
      color: #000000
@media print
  #pdf-viewer
    display: none
</style>