<template>
  <v-row
    v-if="tabs.responded"
    class="align-self-start c-layout"
  >

    <!--  미로그인 접속시  -->
    <v-col
      v-if="!gc_user"
      class="px-4 py-16 text-center"
      cols="12"
    >
      {{ gc_langText.common.alert.text.needLogIn[gc_lang] }}
    </v-col>

    <template v-else>

      <!--  탭 리스트  -->
      <v-col
        class="px-0 px-sm-4"
        cols="12"
      >
        <tabs :list="tabs.list"/>
      </v-col>

      <!--  아이템 리스트  -->
      <template v-if="items.responded && items.list.length > 0">
        <cards
          v-for="(item, i) in items.list"
          :key="'replay-item-' + i"
          :item="item"
          :itemIdx="i"
          @dialogOpen="dialogOpen"
          @questionOpen="questionOpen"
        />
      </template>
      <v-col
        v-else-if="items.responded"
        class="px-0 px-sm-4"
        cols="12"
      >
        <p class="ma-0 py-6 text-center text-h5">
          {{ gc_langText.replay.text.noResult[gc_lang] }}
        </p>
      </v-col>

    </template>

    <!--  아이템 상세 팝업 (다시보기)  -->
    <replay-dialog
      :_show="dialog.show"
      :item="dialog.item"
      :itemIdx="dialog.itemIdx"
      @dialogClose="dialogClose"
      @updateHighWatchTime="updateHighWatchTime"
      @updateWatched="updateWatched"
      @questionOpen="questionOpen"
    />

    <!--  질문 팝업  -->
    <question-dialog
      :_show="question.show"
      :item="question.item"
      @questionClose="questionClose"
    />

  </v-row>
</template>

<script>
import Tabs from "../components/replay/Tabs"
import Cards from "../components/replay/Cards"
import ReplayDialog from "../components/replay/ReplayDialog"
import QuestionDialog from "../components/replay/QuestionDialog"

export default {
  name: "Replay",

  mounted() {
    const it = this

    // 로그인 하지 않은 유저의 경우
    if (!this.gc_user) {
      this.$store.state.pageLoaded = true
      this.tabs.responded = true
      return
    }

    // 카테고리 리스트 요청
    this.api_getReplayCategoryList().finally( () => {
      // 소켓서버 응답으로 Vod 다중 접속 방지 확인 후 내부적으로 아이템 리스트 요청
      it.io_onDuplicateConnected()
    })
  },

  data: () => ({
    // 다중 접속 여부 채크 완료
    duplicateConnectChecked: false,

    // 탭 리스트
    tabs: {
      list: [],
      responded: false
    },

    // 아이탬 관련 객체
    items: {
      list: [],
      responded: false
    },

    // 팝업 관련 객체
    dialog: {
      show: false, // 팝업 토글
      item: {}, // 팝업 내 콘텐츠 정보 객체
      itemIdx: -1 // 리스트 내 콘텐츠 인덱스
    },

    // 질문 팝업 관련 객체
    question: {
      show: false, // 팝업 토글
      item: {} // 질문 할 아이탬 정보 객체
    }
  }),

  components: {
    Tabs,
    Cards,
    ReplayDialog,
    QuestionDialog
  },

  watch: {
    '$route.query'(cur, old) {
      const categoryChange = cur.id === undefined && old.id === undefined
      const needOpen = cur.id !== undefined && old.id === undefined
      const needClose = cur.id === undefined && old.id !== undefined

      // 카테고리 변경의 경우
      if (categoryChange) {
        this.items.responded = false
        this.api_getReplayItemList()
      }
      // vod 컨텐츠 오픈의 경우
      else if (needOpen) {
        const list = this.items.list
        const id = Number( cur.id )
        const idx = list.findIndex( eve => eve.id === id )

        this.$store.state.pageLoaded = true

        if (id && idx > -1) {
          this.dialogOpen(list[idx], idx)
        }
      }
      // 오픈된 vod 컨텐츠를 닫아야 할 경우
      else if (needClose) {
        this.$store.state.pageLoaded = true
        this.dialogClose()
      }
    }
  },

  methods: {
    // 팝업 오픈
    dialogOpen(item, itemIdx) {
      const user_type = this.gc_user ? this.gc_user.type : ''
      const is_admin = this.gc_user ? this.gc_user.admin_level === 0 : false
      const permitted = !!item[`is_authority_type${user_type}`]

      // 사용자가 해당 컨텐츠를 볼 권한이 없는 경우
      if (!is_admin && !permitted) {
        const lang = this.gc_lang
        const text = this.gc_langText.replay.alert.permissionDeny[lang]
        const router = this.$router
        const category = this.$route.query.category

        return this.gm_alertOpen({
          text,
          callback() {
            router.push({ query: { category } })
          }
        })
      }

      // 사용자가 더블 클릭 또는 서로 다른 카드를 연속 클릭했을 경우 로직이 꼬이는 것을 방지
      if (this.dialog.show) { return }

      this.dialog.show = true
      this.dialog.item = item
      this.dialog.itemIdx = itemIdx
    },

    // 팝업 닫기
    dialogClose() {
      this.dialog.show = false
    },

    // 질문 창 열기
    questionOpen(item) {
      // 사용자가 더블 클릭 또는 서로 다른 카드를 연속 클릭했을 경우 로직이 꼬이는 것을 방지
      if (this.question.show) { return }

      this.question.show = true
      this.question.item = item
    },

    // 질문 창 닫기
    questionClose() {
      this.question.show = false
      this.question.item = {}
    },

    // 특정 Vod 의 최종 시청 시각 업데이트
    updateHighWatchTime(seconds, idx) {
      this.items.list[idx].vod_location = seconds
    },

    // 특정 vod 시청 완료 업데이트
    updateWatched(idx) {
      this.items.list[idx].view_complete = 1
    }
  }
}
</script>

<style lang="sass" scoped>

</style>