<template>
  <v-col
    class="pa-0 pa-sm-4 pb-3"
    cols="12"
    sm="6"
    md="4"
  >
    <v-card
      ref="card"
      :to="{ query: {
        id: item.id,
        category: $route.query.category
      } }"
      flat
      tile
    >

      <!--  아이템 섬네일  -->
      <v-img
        class="grey"
        aspect-ratio="1.7778"
        :src="item[`file_thumb`]"
      >

        <!--  버튼: 질문하기  -->
        <v-tooltip
          v-if="gc_user && item.is_use_question"
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click.prevent="$emit('questionOpen', item)"
              class="mt-6 mr-n3"
              color="white"
              elevation="0"
              small
              absolute
              right
              top
              fab
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                color="cyan"
                large
              >
                mdi-help-circle
              </v-icon>
            </v-btn>
          </template>
          <span>Question</span>
        </v-tooltip>

      </v-img>

      <!--  아이템 카테고리 표시 박스  -->
      <v-card-text
        v-if="item[`speaker${gc_lang_prefix}`]"
        class="pa-0 c-relative"
      >
        <span class="px-3 py-2 cyan white--text text-truncate c-card-category-title c-ws-nowrap">
          {{ item[`speaker${gc_lang_prefix}`] }}
        </span>
      </v-card-text>

      <!--  아이템 제목  -->
      <v-card-title class="pt-5">
        <span class="c-text-line2">
          {{ item[`name${gc_lang_prefix}`] }}
        </span>
      </v-card-title>

    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "Cards",

  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    itemIdx: {
      type: Number,
      default: -1
    }
  }
}
</script>

<style lang="sass" scoped>
.c-card-category-title
  position: absolute
  top: 0
  left: 50%
  transform: translate(-50%, -50%)
  max-width: 90%
  width: auto
.c-text-line2
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2
  line-height: 2rem
  height: 4rem
</style>