<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    scrollable
    fullscreen
  >
    <v-card
      class="px-16 py-4 mt-16"
      tile
    >

      <!--  닫기 버튼  -->
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="black"
          @click="show = false"
          text
        >
          <span class="mr-2">Close</span>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>

      <!--  컨텐츠 박스  -->
      <v-card-text class="pa-0 overflow-x-hidden">
        <v-row class="mx-0 mx-md-auto c-layout">
          <v-col
            class="pb-0 px-0"
            cols="12"
            md="10"
            offset-md="1"
          >
            <v-card-title class="pt-0 justify-center cyan--text font-weight-bold">
              {{ item[`name${gc_lang_prefix}`] }}
            </v-card-title>
            <v-card-subtitle class="pb-0 px-0 text-center">
              {{ item[`speaker${gc_lang_prefix}`] }}
              <br v-if="item[`speaker${gc_lang_prefix}`] && item[`location${gc_lang_prefix}`]">
              {{ item[`location${gc_lang_prefix}`] }}
            </v-card-subtitle>
          </v-col>
        </v-row>

        <!--  동영상 박스  -->
        <v-row class="flex-column-reverse flex-sm-row mx-0 mx-md-auto c-layout">
          <v-col
            v-show="loaded"
            class="px-0"
            cols="12"
            md="10"
            offset-md="1"
            ref="vimeoBox"
          >
          </v-col>
          <v-col
            v-show="!loaded"
            class="px-0"
            cols="12"
            md="10"
            offset-md="1"
          >
            <div class="c-vimeo-loading">
              <v-progress-circular
                :size="64"
                :width="8"
                color="cyan"
                indeterminate
              />
            </div>
          </v-col>
        </v-row>

        <!--  질문하기  -->
        <v-row
          v-if="item.is_use_question || item.attach_file"
          class="mx-0 mx-md-auto c-layout"
        >
          <v-col
            class="pa-0"
            cols="12"
            md="10"
            offset-md="1"
          >
            <v-card-subtitle class="py-0 text-right">

              <!--  버튼: 첨부파일  -->
              <v-btn
                v-if="item.attach_file"
                @click="clickAttachFile"
                color="grey"
                depressed
                small
              >
                {{ gc_langText.replay.btn.attachments[gc_lang] }}
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>

              <!--  버튼: 질문하기  -->
              <v-btn
                v-if="item.is_use_question"
                @click.prevent="$emit('questionOpen', item)"
                class="ml-3"
                color="cyan"
                depressed
                dark
                small
              >
                {{ gc_langText.replay.btn.question[gc_lang] }}
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>

            </v-card-subtitle>
          </v-col>
        </v-row>

        <!--  텍스트 컨텐츠  -->
        <v-row
          v-if="item[`contents${gc_lang_prefix}`]"
          class="mx-0 mx-md-auto c-layout"
        >
          <v-col
            class="pa-0"
            cols="12"
            md="10"
            offset-md="1"
          >
            <v-card-text
              class=""
              v-html="item[`contents${gc_lang_prefix}`]"
            />
          </v-col>
        </v-row>

      </v-card-text>

    </v-card>

    <!--  PDF 뷰어  -->
    <replay-dialog-pdf
      :_src="item.attach_file"
      :_show="showPdf"
      @closePdf="showPdf = false"
    />
  </v-dialog>
</template>

<script>
import Player from '@vimeo/player'
import ReplayDialogPdf from "./ReplayDialogPdf"

// 업데이트 해야 할 시간 초 갭
const update_gap = 10

export default {
  name: "ReplayDialog",

  components: {
    ReplayDialogPdf
  },

  updated() {
    const it = this

    // 최초 1회만 새로운 비메오 플레이어 객체 생성 필요시
    if (this.player === null && this.gc_user && this._show && this.$refs.vimeoBox) {
      const player = new Player(this.$refs.vimeoBox, {
        id: this.item.file_vimeo_url,
        responsive: true,
        color: '00AEAF'
      })

      player.ready()
        // 최초 이벤트 헨들러 등록
        .then(() => {
          it.player = player

          /*
          1. 탐색 불가 플레그가 있을 경우
            - seeking 및 seeked 이벤트 헨들러에
              player.setCurrentTime( seconds ) 메서드로 고정
          2. 누적 시청시간 채크
            - playing 또는 seeking 헨들러 호출 시 응답 객체의 seconds 를 기준 시간으로 설정
            - timeupdate 헨들러 호출시 응답 객체의 seconds 와 기준 시간의 차가 10 초 이상일 경우
              시청시간 추가 API 호출
          */

          // 비디오 로드 완료 처리
          player.on('loaded', () => {
            it.loaded = true
          })

          // 비디오 애러
          player.on('error', err => {
            console.error(`name: ${err.name}\nmethod: ${err.method}\nmessage: ${err.message}`)
            it.loaded = true
          })

          // 탐색 금지 이벤트 핸들러 추가 (헨들러 두개를 모두 추가해야 동작함)
          player.on('seeking', it.seekBlock)
          player.on('seeked', it.seekBlock)

          // 시청시간 업데이트
          player.on('timeupdate', it.timeupdate)
          player.on('ended', it.ended)

        })
        // 모종의 이유로 플레이어 객체 생성에 애러가 생긴 경우
        .catch(err => {
          // 잘못 생성된 객체를 파괴하고 다이얼로그를 닫는다.
          player.destroy().then(() => {
            console.error(err)
            alert('video not found')
            it.show = false
          })
          return
        })
    }

    // 비디오 객체가 있을 경우 비디오 로드
    else if (this.player && this._show) {
      // 참조할 비메오 아이디가 없을 경우 강제로 1을 대입 (1은 업는 비디오라 항상 애러가 뜸)
      this.player.loadVideo( this.item.file_vimeo_url || 1 ).catch(()=>{})
    }

    // 비디오 다이얼로그가 닫힐 경우
    else if (!this._show && !this.show) {
      this.player && this.player.unload()
    }
  },

  props: {
    // 부모로부터 팝업 토글 여부를 받는다 (부모 속성이므로 직접 컨트롤 불가)
    _show: {
      type: Boolean,
      default: false
    },

    // 보여줄 컨텐츠 정보 객체
    item: {
      type: Object,
      default: () => ({})
    },

    // 컨텐츠의 리스트 내 인덱스
    itemIdx: {
      type: Number,
      default: -1
    }
  },

  data: () => ({
    // 팝업 토글 여부
    show: false,

    // PDF 뷰어 토글 여부
    showPdf: false,

    // video js 플레이어 객체
    player: null,

    // 비메오 플레이어 로딩 완료 여부
    loaded: false,

    // 다음번 timeupdate 핸들러 호출시 시청시간 API 호출 필요
    needUpdate: false,

    // 시청시간 계산을 위한 객체
    watchTime: {
      isPlaying: false, // 시간 계산을 위한 플레이어 상태값 (플레이어 메서드는 비동기로 사용하지 않음)
      standard: 0, // 누적 시간에 추가할 시간계산을 위한 마지막 재생위치 (초)
      cumulative: 0, // 아직 API 호출로 등록하지 않은 누적 시청시간 (초)
    }
  }),

  watch: {
    // 실질적으로 비메오 비디오 아이디가 바뀔때만 로딩 UI 를 다시 표시
    item(cur, old) {
      if (cur.file_vimeo_url !== old.file_vimeo_url) {
        this.loaded = false
        this.needUpdate = false
      }
    },

    // 부모로부터 전달 받은 속성 변경 값 자체 속성 값과 링크
    _show(val) {
      this.show = val
      val && this.api_updateViews()
    },

    // 자체 속성값 "닫기" 로 변경시 부모 메서드 호출
    show(val) {
      if (!val) {
        this.watchTime.isPlaying = false
        this.watchTime.standard = 0
        this.watchTime.cumulative = 0

        const category = this.$route.query.category
        const query = { category }

        if (!category) {
          delete query.category
        }

        this.$router.push({ query })
      }
    }
  },

  methods: {
    // 조건부 비디오 탐색 금지 로직
    seekBlock(data) {
      // 앞으로 가기 금지모드이고,
      // 시청완료하지 않은 Vod 이고,
      // 최종시청시각보다 탐색시간이 클 경우
      const check = this.item.is_lock &&
        !this.item.view_complete &&
        this.item.vod_location < data.seconds

      // 탐색에 의한 마지막 재생 위치 업데이트
      this.watchTime.standard = data.seconds

      // 채크 조건에 부합하지 않을 경우 탐색 블럭 메서드를 실행하지 않음
      if (!check) { return }

      const last = this.item.vod_location
      const cur = data.seconds

      // 마지막 시청 시간 위치보다 현재 탐색 시간이 클 경우
      if (last < cur) {
        // 업데이트 예약을 초기화
        this.needUpdate = false
        // 비디오 플레이 일시정지
        this.player.pause()
        // 마지막 시청시간 기록지점으로 이동
        this.player.setCurrentTime( last )
      }
    },

    // video 재생 중 마지막 시청시간 업데이트
    timeupdate(data) {
      const cur_sec = Math.floor( data.seconds )

      // 업데이트 예약이 있을 경우
      if (this.needUpdate) {
        this.needUpdate = false // 업데이트 예약 플레그 초기화

        // "업데이트 할 시간" < "마지막 업데이트 시간" = 업데이트 실행 유효
        // (중요: "영상 탐색 금지 && 시청 미완료" 상태에서 강제로 영상 끝까지 탐색을 시도할 경우,
        // "seekBlock" 핸들러 이후 "timeupdate" 핸들러가 호출되어 "업데이트 예약" 로직이 실행됨.
        // 이후 "timeupdate" 호출되므로 "업데이트 예약이 있을 경우" 로직을 실행하게 됨.
        // 이 때 업데이트 해야 할 시간은 마지막 시청시간과 동일한 값이므로,
        // 무의미한 api 호출을 막기 위한 조건문)
        if (this.item.vod_location < cur_sec) {
          this.api_updateWatchProgress(cur_sec)
        }

        // 누적 시청시각 업데이트 API 호출
        this.api_vodUpdateWatchTime()
      }
      // 업데이트 예약이 필요한지 판단하는 로직
      else {
        const sec = data.seconds - this.watchTime.standard

        // 시청 시간이 2초 이상일 경우 탐색에 의한 잘못된 상태로 인지하고 정보 업데이트 하지 않음
        if (sec > 2) {
          this.watchTime.standard = data.seconds
        }
        // 시청 시간이 1 초 이상일 경우 정보 업데이트
        else if (sec > 1) {
          this.watchTime.cumulative += sec
          this.watchTime.standard = data.seconds
        }

        // 현재 시청시간이 0이 아닌지 여부
        const notBeginning = Boolean(cur_sec)
        // 예상되는 마지막 업데이트 시간
        const expectLastUpdateTime = cur_sec - (cur_sec % update_gap)
        // 마지막 업데이트 시간보다 예상되는 마지막 업데이트 시간이 더 클 경우
        const notUpdated = this.item.vod_location < expectLastUpdateTime
        // 누적 10초 이상
        const cumulative = this.watchTime.cumulative >= 10

        // 시청 시간 업데이트 필요 여부 값 갱신
        this.needUpdate = (notBeginning && notUpdated) || cumulative
      }
    },

    // video 시청 완료 시청시간 업데이트
    ended(data) {
      const it = this
      const duration = Math.floor( data.seconds )
      // (남은 시간초) = (영상길이) - (마지막 업데이트 시청시간)
      const leftoverSeconds = duration - this.item.vod_location
      // (탐색에 의한 ended 이벤트 헨들러 호출하지 않음 여부) = (남은 시간 초) <= (업데이트 갭)
      // 남은 시간 초가 업데이트 갭보다 클 경우 탐색금지 모드에서 강제로 시청완료 시도로 관주
      const notSeeked = leftoverSeconds <= update_gap
      // 시청완료 업데이트 필요 여부
      const needUpdate = notSeeked && !this.item.view_complete

      // 비디오 시청이 완료가 아닌 경우
      if (needUpdate) {
        // 업데이트 예약을 초기화
        this.needUpdate = false

        it.api_vodUpdateWatchTime()
        // API 시청시간 업데이트
        // (시청시간이 비디오 시간 이상일 경우 시청완료는 서버에서 자동으로 적용함)
        this.api_updateWatchProgress( duration )
        it.api_vodWatchedDone()
      }
    },

    // 첨부파일 분기처리
    clickAttachFile() {
      const isPdf = this.item.attach_file
        .replace(/\?v=.+$/, '')
        .match(/.{4}$/)?.[0] === '.pdf'

      isPdf ?
        (this.showPdf = true) :
        window.open(this.item.attach_file, '_blank')
    }
  }
}
</script>

<style lang="sass" scoped>
.c-vimeo-loading
  background-color: #eeeeee
  position: relative
  width: 100%
  padding: 56.25% 0 0 0 !important
  >*
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
</style>